import React from 'react';
import { motion } from 'framer-motion';

import DonationForm from '../components/DonationForm';
import ResultsSection from '../components/PagesResultsSection';

const Donate = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <DonationForm />
      <ResultsSection/>
    </motion.div>
  );
};

export default Donate;
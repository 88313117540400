import { useParams } from 'react-router-dom';

const galleryImages = {
  'tech-institute': Array.from({ length: 27 }, (_, index) => require(`../assets/images/tech_institute/${index + 1}.jpg`)),
  'beauty-wellness': Array.from({ length: 35 }, (_, index) => require(`../assets/images/beauty_wellness/${index + 1}.jpg`)),
  'kamal-vihar-school': Array.from({ length: 22 }, (_, index) => require(`../assets/images/kamal_vihar_school/${index + 1}.jpg`)),
  'jaitewala-school': Array.from({ length: 9}, (_, index) => require(`../assets/images/jaitewala_school/${index + 1}.jpg`)),
};

const categoryNames = {
  'tech-institute': 'Jps institute of technical and vocational studies, Jaitewala HSR road Jalandhar',
  'beauty-wellness': 'Milano International institute of beauty and wellness, Jalandhar',
  'kamal-vihar-school': 'Jalandhar public school kamal vihar, Near Surya Enclave, Jalandhar',
  'jaitewala-school': 'Jalandhar Public school Jaitewala, Patara',
};

const CategoryGallery = () => {
  const { category } = useParams(); 
  const images = galleryImages[category];
  const categoryName = categoryNames[category];

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 py-14 px-6">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-4xl font-extrabold text-gray-800 leading-snug mb-8 text-left">
          {categoryName}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {images.map((image, index) => (
            <div
              key={index}
              className="bg-white shadow-lg overflow-hidden transform transition-transform duration-500 md:hover:scale-105 md:hover:shadow-xl border-4 border-green-600"
            >
              <img src={image} alt={`image${index + 1}`} className="w-full h-[300px] object-cover" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CategoryGallery;

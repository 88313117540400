import React from 'react';
import HeroSection from '../components/HeroSection';
import AboutUs from './About';
import ContactUs from './Contact';
// import HomeMissionSection from './HomePageMission';
import ObjectivesSection from '../components/Objectives';

const Home = () => {
  return (
    <>
    <HeroSection />
    <AboutUs />
    {/* <HomeMissionSection /> */}
    <ObjectivesSection/>
    <ContactUs />
    </>
  );
};

export default Home;
import React from 'react';

const TermsConditions = () => {
  return (
    <div className="container mx-auto px-6 lg:px-40 py-12">
      <div className="bg-white rounded-lg shadow-lg p-6 lg:p-10">

        <h3 className="text-xl text-green-600 mb-4">TERMS & CONDITIONS</h3>
        <h1 className="text-3xl font-bold mb-8">Terms and Conditions</h1>

        <div className="text-gray-700 space-y-8">
          <p>
            Welcome to the website of Akal Educational Society ("we," "us," or "our"). By accessing or using our website, you agree to comply with these Terms and Conditions. Please read them carefully before using our services.
          </p>

          <div>
            <h2 className="text-2xl font-semibold mb-4">1. Acceptance of Terms</h2>
            <p>
              By using our website, you agree to these Terms and Conditions and our Privacy Policy. If you do not agree with these terms, please do not use our website.
            </p>
          </div>

          <div>
            <h2 className="text-2xl font-semibold mb-4">2. Use of Website</h2>
            <p>
              You agree to use our website for lawful purposes only and not to engage in any activity that may harm our website, interfere with its operation, or access its content in a manner that violates applicable laws or regulations.
            </p>
          </div>

          <div>
            <h2 className="text-2xl font-semibold mb-4">3. Donations</h2>
            <p>
              All donations made to Akal Educational Society are voluntary. We use third-party payment processors to handle donations securely. By making a donation, you agree to the terms and conditions of the respective payment processor.
            </p>
          </div>

          <div>
            <h2 className="text-2xl font-semibold mb-4">4. Intellectual Property</h2>
            <p>
              All content on our website, including text, images, logos, and graphics, is the property of Akal Educational Society or its content suppliers and is protected by copyright laws. You may not reproduce, distribute, or use any content without our prior written consent.
            </p>
          </div>

          <div>
            <h2 className="text-2xl font-semibold mb-4">5. Third-Party Links</h2>
            <p>
              Our website may contain links to third-party websites. We are not responsible for the content or privacy practices of these websites. We encourage you to review their terms and privacy policies before engaging with them.
            </p>
          </div>

          <div>
            <h2 className="text-2xl font-semibold mb-4">6. Limitation of Liability</h2>
            <p>
              Akal Educational Society shall not be liable for any damages arising from the use of our website or services, including but not limited to direct, indirect, incidental, or consequential damages.
            </p>
          </div>

          <div>
            <h2 className="text-2xl font-semibold mb-4">7. Indemnification</h2>
            <p>
              You agree to indemnify and hold harmless Akal Educational Society, its officers, directors, employees, and agents from any claims, damages, liabilities, and expenses arising from your use of our website or breach of these Terms and Conditions.
            </p>
          </div>

          <div>
            <h2 className="text-2xl font-semibold mb-4">8. Changes to These Terms</h2>
            <p>
              We may update these Terms and Conditions periodically. We will notify you of any changes by posting the new terms on our website. Your continued use of our website after any changes constitutes acceptance of the new terms.
            </p>
          </div>

          <div>
            <h2 className="text-2xl font-semibold mb-4">9. Contact Us</h2>
            <p>
              If you have any questions or concerns about these Terms and Conditions, please contact us at:
            </p>
            <p className="mt-2">
              <strong>Akal Educational Society</strong><br />
              30, Modern Market, Nehru Garden Road, Jalandhar<br />
              +91 9915494600<br />
              <a href="mailto:support@akaledusociety.com" className="text-green-600">support@akaledusociety.com</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;

import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

import logo from "../assets/images/logopng.png";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const activeClassName = "font-bold md:border-b-4 md:border-green-600 md:pb-2";

  return (
    <nav className="bg-white text-gray-800 p-3 shadow-md fixed top-0 left-0 w-full z-40">
      <div className="flex justify-between items-center max-w-7xl mx-auto px-0">
        <div className="flex items-center">
          <NavLink to="/" className="text-xl font-bold">
            <img src={logo} alt='logo' className='h-15 w-40'/>
          </NavLink>
        </div>

        <div className="hidden md:flex space-x-6">
          <NavLink to="/" exact className={({ isActive }) => isActive ? activeClassName : "pb-2"}>
            Home
          </NavLink>
          <NavLink to="/about" className={({ isActive }) => isActive ? activeClassName : "pb-2"}>
            About Us
          </NavLink>
          <NavLink to="/mission" className={({ isActive }) => isActive ? activeClassName : "pb-2"}>
            Mission
          </NavLink>
          <NavLink to="/gallery" className={({ isActive }) => isActive ? activeClassName : "pb-2"}>
            Gallery
          </NavLink>
          <NavLink to="/contact" className={({ isActive }) => isActive ? activeClassName : "pb-2"}>
            Contact Us
          </NavLink>
        </div>

        <div className="flex items-center space-x-4 pr-4 md:pr-0"> {/* Added `pr-4` for mobile view padding */}
          <NavLink to="/donate" className="hidden md:inline-block bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-md">
            Donate Now
          </NavLink>
          <button onClick={toggleMenu} className="md:hidden focus:outline-none">
            <motion.div
              className={`block w-6 h-0.5 bg-black mb-1 transform transition duration-300 ${isOpen ? 'opacity-0' : ''}`}
            ></motion.div>
            <motion.div
              className={`block w-6 h-0.5 bg-black mb-1 transform transition duration-300 ${isOpen ? 'rotate-45 translate-y-1' : ''}`}
            ></motion.div>
            <motion.div
              className={`block w-6 h-0.5 bg-black transform transition duration-300 ${isOpen ? '-rotate-45 -translate-y-1' : ''}`}
            ></motion.div>
          </button>
        </div>
      </div>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            className="md:hidden bg-white shadow-md"
          >
            <div className="flex flex-col items-start space-y-4 mt-4 pb-1 text-gray-800 px-4">
              <NavLink to="/" exact className={({ isActive }) => isActive ? "font-bold" : ""} onClick={toggleMenu}>
                Home
              </NavLink>
              <NavLink to="/about" className={({ isActive }) => isActive ? "font-bold" : ""} onClick={toggleMenu}>
                About Us
              </NavLink>
              <NavLink to="/mission" className={({ isActive }) => isActive ? "font-bold" : ""} onClick={toggleMenu}>
                Mission
              </NavLink>
              <NavLink to="/gallery" className={({ isActive }) => isActive ? "font-bold" : ""} onClick={toggleMenu}>
                Gallery
              </NavLink>
              <NavLink to="/contact" className={({ isActive }) => isActive ? "font-bold" : ""} onClick={toggleMenu}>
                Contact Us
              </NavLink>
              <NavLink to="/donate" className="bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-md w-full text-center" onClick={toggleMenu}>
                Donate Now
              </NavLink>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
};

export default Navbar;

import { Link, NavLink } from 'react-router-dom';

import logo from "../assets/images/logopng.png";
import { FaEnvelope, FaMapMarkerAlt, FaPhoneAlt } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-10">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-start">
          <div className="mb-8 md:mb-9 w-full md:w-1/3">
            <div className="">
              <img src={logo} alt="logo" className="h-15 w-40" />
            </div>
            <p className="text-md mt-2">
              We empower communities with education, healthcare, and social welfare, uplifting the underprivileged and fostering a brighter future.
            </p>
          </div>
          <nav className="mb-8 md:mb-0 w-full md:w-1/3 flex flex-col md:items-center">
            <ul className="flex flex-col space-y-2 text-md">
              <li><Link to="/" className="hover:underline">Home</Link></li>
              <li><Link to="/about" className="hover:underline">About Us</Link></li>
              <li><Link to="/mission" className="hover:underline">Mission</Link></li>
              <li><Link to="/gallery" className="hover:underline">Gallery</Link></li>
              <li><Link to="/contact" className="hover:underline">Contact Us</Link></li>
            </ul>
          </nav>
          <div className="w-full md:w-1/2 text-start">
            <div className="flex flex-col gap-1 space-y-4 mt-10 md:mt-0 mb-8">
              <div className="flex items-center">
                <FaMapMarkerAlt className="text-green-600 mr-2 " />
                <span>30, Modern Market, Nehru Garden Road, Jalandhar</span>
              </div>
              <div className="flex items-center">
                <FaPhoneAlt className="text-green-600 mr-2" />
                <span>+91-9915494600</span>
              </div>
              <div className="flex items-center">
                <FaEnvelope className="text-green-600 mr-2" />
                <span>support@akaledusociety.com</span>
              </div>
              <NavLink
                to="/donate"
                className="bg-green-600 hover:bg-green-700 text-white px-4 mt-10 md:mt-0 py-2 rounded-md w-40 text-center"
              >
                Donate Now
              </NavLink>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between items-center mt-10 border-t border-gray-700 pt-4">
          <p className="text-center md:text-left text-sm mb-4 md:mb-0">
            &copy; {new Date().getFullYear()} Akal Educational Society. All rights reserved.
          </p>
          <div className="flex space-x-4 text-sm">
            <Link to="/privacy-policy" className="hover:underline">Privacy Policy</Link>
            <Link to="/terms-and-conditions" className="hover:underline">Terms & Conditions</Link>
            <Link to="/refund-and-cancellation" className='hover:underline'>Refund and Cancellation</Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import { Link } from 'react-router-dom';

const categories = [
  {
    name: 'Jps institute of technical and vocational studies, Jaitewala HSR road Jalandhar',
    slug: 'tech-institute', 
    image: require('../assets/images/tech_institute/4.jpg')
  },
  {
    name: 'Milano International institute of beauty and wellness, Jalandhar',
    slug: 'beauty-wellness',
    image: require('../assets/images/beauty_wellness/34.jpg')
  },
  {
    name: 'Jalandhar public school kamal vihar, Near Surya Enclave, Jalandhar',
    slug: 'kamal-vihar-school',
    image: require('../assets/images/kamal_vihar_school/4.jpg')
  },
  {
    name: 'Jalandhar Public school Jaitewala, Patara',
    slug: 'jaitewala-school',
    image: require('../assets/images/jaitewala_school/4.jpg')
  }
];

const Gallery = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 py-14 px-6">
      <div className="max-w-7xl mx-auto">
        <h3 className="text-xl text-green-600">OUR GALLERY</h3>
        <h2 className="text-4xl font-extrabold text-gray-800 leading-snug mb-8 text-left">
          Categories
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {categories.map((category, index) => (
            <Link
              key={index}
              to={`/gallery/${category.slug}`}
              className="mb-5 cursor-pointer bg-white shadow-lg rounded-lg overflow-hidden transform transition-transform duration-500 md:hover:scale-105 md:hover:shadow-xl md:hover:border-b-4 md:border-green-600 border-b-4 border-green-600 md:border-b-0"
            >
              <img src={category.image} alt={category.name} className="w-full h-[200px] md:h-[300px] object-cover" />
              <div className="p-4">
                <h3 className="text-lg font-semibold text-gray-800">{category.name}</h3>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Gallery;

import image6 from "../assets/images/image6.jpg";
import image7 from "../assets/images/image7.jpg";
import { useNavigate } from 'react-router-dom';
import HelpSection from '../components/HelpSection';

const MissionSection = () => {
  const navigate = useNavigate();

  const handleDonateNowClick = () => {
    navigate('/donate');
  };

  return (
    <>
      <div 
        className="flex flex-col items-center justify-center min-h-screen px-6 md:px-12 bg-white pt-12 md:pt-8 pb-20 md:pb-0"
      >
        <div className="flex flex-col items-center md:items-start md:flex-row w-full max-w-7xl mx-auto">
          <div 
            className="md:w-2/3 text-left md:pr-10 mb-20"
          >
            <h3 className="text-xl text-green-600">OUR MISSION</h3>
            <h2 className="text-3xl md:text-4xl font-extrabold text-gray-800 leading-snug mb-4">
              We are on a mission to <span className='text-green-600'>help the helpless</span>
            </h2>
            <p className="text-gray-600 mb-8">
              Our NGO is dedicated to uplifting marginalized communities by providing education, healthcare, and vocational training to children, women, and the 
              underprivileged. We run schools, hospitals, and shelters, offering vital services such as medical aid and counseling. We are committed to promoting 
              national integration, communal harmony, and environmental protection while preserving cultural heritage. Our efforts extend to family welfare, women's 
              empowerment, and supporting martyrs' families, as well as fighting against social injustice and discrimination.
            </p>
            <p className="text-gray-600 mb-8">
              We also focus on animal protection, sustainable agriculture, and promoting traditional arts and therapies. Our organization actively participates in 
              disaster relief, legal assistance for the needy, and raising awareness through health camps and educational programs. Through these initiatives, we aim 
              to create a positive impact on society, fostering unity and support for all.
            </p>
            <button 
              onClick={handleDonateNowClick} 
              className="bg-green-600 hover:bg-green-700 text-white font-bold py-3 px-6 rounded-lg shadow-lg transform transition-transform duration-300"
            >
              Donate Now
            </button>
          </div>
          <div 
            className="md:w-1/2 relative flex justify-center md:justify-end -mt-10 md:mt-10"
          >
            <img
              src={image6}
              alt="image6"
              className="w-80 md:w-96 h-68 md:h-[400px] object-cover shadow-xl rounded-lg"
              style={{ zIndex: 10 }}
            />
            <img
              src={image7}
              alt="image7"
              className="w-32 md:w-48 h-[150px] md:h-[200px] object-cover shadow-lg rounded-lg absolute -bottom-8 md:-bottom-7 left-6 md:left-28 transform -translate-x-1/4 translate-y-1/4 "
              style={{ zIndex: 20 }}
            />
          </div>
        </div>
      </div>
      <HelpSection />
    </>
  );
};

export default MissionSection;

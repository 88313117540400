import t1 from "../assets/images/t1.jpg";
import t2 from "../assets/images/t2.jpg";
import t3 from "../assets/images/t3.jpg";
import { useNavigate } from "react-router-dom";

const Team = () => {
    const navigate = useNavigate();

    const handleDonateNowClick = () => {
        navigate('/donate'); 
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-full bg-white pb-10 px-6 md:px-0 pt-24">
            <div
                className="max-w-7xl mx-auto"
            >
                <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-8">
                    <div className="flex flex-col">
                        <h3 className="text-xl text-green-600">OUR TEAM</h3>
                        <h2 className="text-4xl font-extrabold text-gray-800 leading-snug mb-4">
                            Meet our leaders
                        </h2>
                        <p className="text-gray-600 max-w-xl">
                            Meet the Visionary Leaders and Dedicated Team Behind Our Mission for a Better Tomorrow.
                        </p>
                    </div>
                    <button 
                        onClick={handleDonateNowClick} 
                        className="bg-green-600 md:hover:bg-green-700 text-white font-bold py-3 px-6 rounded-lg mt-4 md:mt-0"
                    >
                        Donate now
                    </button>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 ">
                    {[{ img: t1, name: "S. Maninder Jit Singh", role: "Chairman" },
                      { img: t2, name: "S. Tarundeep Singh", role: "Vice Chairman" },
                      { img: t3, name: "Dr. Simaljit Kaur", role: "General Secretary" }]
                      .map((leader, index) => (
                        <div
                            key={index}
                            className="bg-gray-50 rounded-lg shadow-lg overflow-hidden transform transition duration-500 md:hover:scale-105 md:hover:bg-gray-100 group border-b-4 border-green-600"
                        >
                            <img src={leader.img} alt={leader.name} className="w-full h-[400px] object-cover" />
                            <div className="p-6">
                                <h3 className="text-xl font-bold text-gray-800 mb-2 group-hover:text-green-600 transition duration-300">
                                    {leader.name}
                                </h3>
                                <p className="text-gray-500">{leader.role}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Team;

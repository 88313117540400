import React from 'react';
import CountUp from 'react-countup';

const PagesResultsSection = () => {
  return (
    <div className="bg-white py-8">
      <div className="max-w-7xl mx-auto text-center">
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-8">
          <div>
            <span className="text-4xl md:text-5xl font-extrabold text-gray-800">
              <CountUp end={200} duration={2} />
              <span className="text-green-600">m</span>
            </span>
            <p className="text-gray-600">Humans impacted</p>
          </div>
          <div>
            <span className="text-4xl md:text-5xl font-extrabold text-gray-800">
              <CountUp end={50} duration={2} />
              <span className="text-green-600">k</span>
            </span>
            <p className="text-gray-600">Collaborators</p>
          </div>
          <div>
            <span className="text-4xl md:text-5xl font-extrabold text-gray-800">
              <CountUp end={370} duration={2} />
              <span className="text-green-600">k</span>
            </span>
            <p className="text-gray-600">Donations</p>
          </div>
          <div>
            <span className="text-4xl md:text-5xl font-extrabold text-gray-800">
              <CountUp end={100} duration={2} />
              <span className="text-green-600">+</span>
            </span>
            <p className="text-gray-600">Assisted communities</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PagesResultsSection;

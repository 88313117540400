import React, { useState } from 'react';
import axios from 'axios';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa'; 

const ContactUs = () => {
  const [subject, setSubject] = useState(''); 
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    let errorMessages = [];

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!subject || !email || !message) {
      errorMessages.push('Please fill in all fields.');
    } else {
      if (!subject) {
        errorMessages.push('Please enter the subject.');
      }
      if (!email) {
        errorMessages.push('Please enter your email.');
      } else if (!emailPattern.test(email)) {
        errorMessages.push('Please enter a valid email address.');
      }
      if (!message) {
        errorMessages.push('Please enter your message.');
      }
    }

    if (errorMessages.length > 0) {
      setStatus(errorMessages.join(' '));
      return;
    }

    setStatus('Sending...');

    axios.post('https://monkfish-app-vo3zt.ondigitalocean.app/send', {
      subject,  
      email,
      message
    }).then(response => {
      setStatus('Message sent successfully!');
      setSubject(''); 
      setEmail('');
      setMessage('');
    }).catch(error => {
      setStatus('Failed to send message. Please try again.');
    });
  };


  return (
    <div className="flex flex-col items-center justify-center min-h-screen pt-8 md:pt-5 pb-[3rem] px-2 md:px-0 bg-cover bg-center bg-gray-50">
      <div
        className="bg-white bg-opacity-80 max-w-4xl mx-auto flex flex-col md:flex-row items-center shadow-lg rounded-lg overflow-hidden pb-4 md:pb-0"
      >
        <div className="md:w-1/2 p-8 md:p-12">
          <h4 className="text-xl text-green-600">CONTACT US</h4>
          <h2 className="text-3xl font-bold text-gray-800 mb-4">
            Want To Know More? <br /> Reach out to us!
          </h2>
          <p className="text-gray-600 mb-8">
            We would love to hear from you! Please fill out the form below and we'll get in touch with you as soon as possible.
          </p>
          
          <div className="space-y-4 mb-8">
            <div className="flex items-center">
              <FaMapMarkerAlt className="text-green-600 mb-6 mr-2 -ml-1 w-6" />
              <span>30, Modern Market, Nehru Garden Road, Jalandhar</span>
            </div>
            <div className="flex items-center">
              <FaPhoneAlt className="text-green-600 mr-2" />
              <span>+91-9915494600
              </span>
            </div>
            <div className="flex items-center">
              <FaEnvelope className="text-green-600 mr-2" />
              <span>support@akaledusociety.com</span>
            </div>
          </div>

        </div>

        <div className="md:w-1/2 p-8 md:p-12 bg-gray-200">
          <form onSubmit={handleSubmit} noValidate className="space-y-6">
            <div>
              <label htmlFor="subject" className="block text-sm font-medium text-black">Subject</label>
              <input
                type="text"
                id="subject"  
                value={subject}
                onChange={(e) => setSubject(e.target.value)} 
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                placeholder="Subject"
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-black">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                placeholder="Your Email"
              />
            </div>
            <div>
              <label htmlFor="message" className="block text-sm font-medium text-black">Message</label>
              <textarea
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500"
                rows="4"
                placeholder="Your Message"
              ></textarea>
            </div>
            <button
              type="submit"
              className="w-full bg-green-600 hover:bg-green-700 text-white font-bold py-3 px-6 rounded-lg shadow-lg transform transition-transform duration-300"
              whileTap={{ scale: 0.95 }}
            >
              Send Message
            </button>
          </form>
          <p className="mt-4 text-sm text-red-600 font-bold">{status}</p> 
          <p className="mt-1 text-xs text-black">
            We'll get back to you within 2-3 business days.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;

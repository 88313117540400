import React from 'react';
import image4 from "../assets/images/image4.jpg";
import image5 from "../assets/images/image5.webp";
import Team from '../components/Team';

const AboutUs = () => {  
  return (
    <>
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 pb-[5.5rem] px-6 md:px-0 pt-10">
        <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-center">
          
          <div
            className="md:w-3/5 mb-8 md:mb-0"
          >
            <h3 className="text-xl text-green-600">ABOUT US</h3>
            <h2 className="text-3xl md:text-4xl font-extrabold text-gray-800 leading-snug mb-4">
              How we make a <span className='text-green-600'>difference.</span>
            </h2>
            <p className="text-gray-600">
              The Akal Educational Society, headquartered at 30, Modern Market, Nehru Garden Road, Jalandhar, operates under the leadership of Maninder Jit Singh, 
              who serves as the lifetime Chairman. The Society comprises 7 to 11 members, with the Chairman holding the authority to induct new members, form committees, 
              and make key decisions, including the appointment and removal of members. The property and finances of the Society are managed by the Chairman, who also 
              has the power to delegate responsibilities.
            </p>
            <br />
            <p className="text-gray-600">
              The Society oversees various educational and healthcare institutions, ensuring their smooth operation through a Managing Committee that functions under 
              the Chairman's guidance. The Chairman's decisions are final, and any disputes are resolved internally without recourse to legal action. The Society is 
              committed to using its resources solely for its intended objectives, including the promotion of education and community welfare.
            </p>
          </div>

          <div className="md:w-2/5 flex justify-center items-center relative">
            <img
              src={image4}
              alt="image4"
              className="w-[500px] h-[350px] object-cover rounded-lg shadow-lg md:w-[400px] md:h-[500px]"
            />
            <img
              src={image5}
              alt="image5"
              className="w-64 h-48 object-cover rounded-lg shadow-lg absolute top-60 md:top-80 left-10 md:left-72"
            />
          </div>
        </div>
      </div>
      <Team className="bg-gray-100" />
    </>
  );
};

export default AboutUs;

import React from 'react';

const RefundAndCancelltion = () => {
  return (
    <div className="container mx-auto px-6 lg:px-40 py-12">
      <div className="bg-white rounded-lg shadow-lg p-6 lg:p-10">

        <h3 className="text-xl text-green-600 mb-4">Refund and Cancellation</h3>
        <h1 className="text-3xl font-bold mb-8">Refund and Cancellation</h1>

        <div className="text-gray-700 space-y-8">
          <p>
          Refund and Cancellation policy is not applicable as this is an NGO and we are accepting donations from donors.
          </p>
          <p>
              If you have any questions or concerns about these, please contact us at:
            </p>
            <p className="mt-2">
              <strong>Akal Educational Society</strong><br />
              30, Modern Market, Nehru Garden Road, Jalandhar<br />
              +91 9915494600<br />
              <a href="mailto:support@akaledusociety.com" className="text-green-600">support@akaledusociety.com</a>
            </p>
        </div>
      </div>
    </div>
  );
};

export default RefundAndCancelltion;

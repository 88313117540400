import React from "react";
import education from "../assets/images/image2.jpeg";
import environment from "../assets/images/1.jpg";
import healthcare from "../assets/images/image3.jpg";
import { useNavigate } from "react-router-dom";

const images = {
  education: education,
  environment: environment,
  healthcare: healthcare,
};

const objectives = [
  {
    title: "Education",
    description: " Our mission is to provide quality education to those in need, fostering growth and opportunity. By empowering individuals with knowledge, we create a foundation for a better future and help break the cycle of poverty.",
    image: images.education,
    imageFirst: true, 
  },
  {
    title: "Environment",
    description: "We are committed to preserving natural resources and promoting sustainable practices. Through education, advocacy, and community action, we strive to create a greener, healthier planet for current and future generations.",
    image: images.environment,
    imageFirst: false, 
  },
  {
    title: "Healthcare",
    description: "Our focus is on providing essential healthcare services to underserved populations. By improving health outcomes, we enhance quality of life and build resilient communities equipped to face the challenges of tomorrow.",
    image: images.healthcare,
    imageFirst: true,   },
];

const ObjectivesSection = () => {

    const navigate=useNavigate();

    const handleClick=()=>{
        navigate("/mission")
    }
  return (
    <section className="pt-16 bg-white pb-1">
        <div className="max-w-7xl mx-auto flex flex-col md:flex-row">
          
          <div className="md:w-3/5 md:mb-0 pl-4">
            <h3 className="text-xl text-green-600">OBJECTIVES</h3>
            <h2 className="text-3xl md:text-4xl font-extrabold text-gray-800 leading-snug mb-4">
              Key Focus Areas
            </h2>
            </div>
        </div>
      <div className="pt-10 container mx-auto px-4 md:px-10 lg:px-40">
        {objectives.map((obj, index) => (
          <div
            key={index}
            className={`flex flex-col lg:flex-row items-center justify-between mb-16 ${
              obj.imageFirst ? "lg:flex-row" : "lg:flex-row-reverse"
            }`}
          >
            <div
              className="w-full lg:w-5/12 mb-8 lg:mb-0 flex justify-center"
            >
              <img
                src={obj.image}
                alt={obj.title}
                className="rounded-lg shadow-lg object-cover h-64 w-full lg:h-96 lg:w-auto"
              />
            </div>

            <div
              className="w-full lg:w-6/12 text-left"
            >
              <h2 className="text-3xl font-bold text-gray-800 mb-4">{obj.title}</h2>
              <p className="text-lg text-gray-600 mb-6">{obj.description}</p>
              <button onClick={handleClick} className="bg-green-600 hover:bg-green-700 text-white font-semibold py-2 px-4 rounded-md transition duration-300">
                Learn More
              </button>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ObjectivesSection;

import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap, faTree, faHeartbeat } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import ThankYouModal from "./ThankYouModal";

const DonationPage = () => {
  const [citizenship, setCitizenship] = useState("indian"); 
  const [currency, setCurrency] = useState("INR"); 
  const [amount, setAmount] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pan, setPan] = useState(""); 
  const [declaration, setDeclaration] = useState(false);
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);

  const currencySymbols = {
    INR: "₹",
    USD: "$",
    EUR: "€",
    GBP: "£",
    CAD: "$",
  };

  const handleCitizenshipChange = (value) => {
    setCitizenship(value);
    if (value === "indian") {
      setCurrency("INR");
    } else if (value === "foreign") {
      setCurrency("USD");
    } else {
      setCurrency("USD"); 
    }
  };

  const handlePayment = async () => {
    if (!amount || !name || !email || !contact || !address || !pincode || !city || !state) {
      setError("All fields are required to proceed with the donation.");
      return;
    }

    setError("");
    try {
      const response = await axios.post("https://monkfish-app-vo3zt.ondigitalocean.app/createOrder", {
        amount: amount,
        currency: currency,
      });
      const { order } = response.data;

      const options = {
        key: process.env.RAZORPAY_KEY_ID,
        amount: order.amount,
        currency: order.currency,
        name: "Akal Educational Society",
        description: "Donation Payment",
        order_id: order.id,
        handler: function (response) {
          setShowModal(true);
          clearFormFields();
        },
        prefill: {
          name: name,
          email: email,
          contact: contact,
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error("Error initiating payment:", error);
    }
  };

  const clearFormFields = () => {
    setAmount("");
    setName("");
    setEmail("");
    setContact("");
    setAddress("");
    setPincode("");
    setCity("");
    setState("");
    setPan("");
  };

  return (
    <div className="flex flex-col items-center min-h-full gap-10 bg-sky-50 px-6 pb-10 md:pb-10 md:px-10 lg:px-20 pt-16">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 w-full max-w-7xl">
        <div className="flex flex-col w-full max-w-lg space-y-8">
          <div className="bg-white p-6 md:p-8 rounded-lg shadow-md w-full max-w-md border-t-4 border-green-600">
            <div className="mb-6">
              <h3 className="text-md font-semibold mb-2">Choose your citizenship</h3>
              <div className="flex justify-center space-x-4">
                <label>
                  <input
                    type="radio"
                    name="citizenship"
                    value="indian"
                    checked={citizenship === "indian"}
                    onChange={() => handleCitizenshipChange("indian")}
                  />{" "}
                  Indian Citizen
                </label>
                <label>
                  <input
                    type="radio"
                    name="citizenship"
                    value="foreign"
                    checked={citizenship === "foreign"}
                    onChange={() => handleCitizenshipChange("foreign")}
                  />{" "}
                  Foreign Citizen
                </label>
                <label>
                  <input
                    type="radio"
                    name="citizenship"
                    value="nri"
                    checked={citizenship === "nri"}
                    onChange={() => handleCitizenshipChange("nri")}
                  />{" "}
                  NRI
                </label>
              </div>
            </div>

            <div className="flex items-center border border-gray-300 rounded-lg overflow-hidden mb-4">
              <span className="border-r border-gray-300 p-2 text-gray-700">
                {currencySymbols[currency]}
              </span>
              <input
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                placeholder="Enter Amount"
                className="w-full p-2"
              />
              {citizenship === "nri" || citizenship === "foreign" ? (
                <select
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
                className="border-l border-gray-300 p-2 bg-white"
                >
                  <option value="INR">INR</option>
                  <option value="USD">USD</option>
                  <option value="EUR">EUR</option>
                  <option value="GBP">GBP</option>
                  <option value="CAD">CAD</option>
                </select>
              ) : null}
            </div>

            <input
              type="text"
              placeholder="Full Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full mb-4 p-2 border border-gray-300 rounded-lg"
            />
            <input
              type="email"
              placeholder="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full mb-4 p-2 border border-gray-300 rounded-lg"
            />
            <input
              type="text"
              placeholder="Phone Number"
              value={contact}
              onChange={(e) => setContact(e.target.value)}
              className="w-full mb-4 p-2 border border-gray-300 rounded-lg"
            />
            <input
              type="text"
              placeholder="Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              className="w-full mb-4 p-2 border border-gray-300 rounded-lg"
            />
            <input
              type="text"
              placeholder="Pincode"
              value={pincode}
              onChange={(e) => setPincode(e.target.value)}
              className="w-full mb-4 p-2 border border-gray-300 rounded-lg"
            />
            <input
              type="text"
              placeholder="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              className="w-full mb-4 p-2 border border-gray-300 rounded-lg"
            />
            <input
              type="text"
              placeholder="State"
              value={state}
              onChange={(e) => setState(e.target.value)}
              className="w-full mb-4 p-2 border border-gray-300 rounded-lg"
            />
            {citizenship === "indian" && (
              <>
              <input
                type="text"
                placeholder="PAN Number"
                value={pan}
                onChange={(e) => setPan(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-lg mb-4"
              />
              <p className="text-green-600 font-normal md:font-bold mb-5">
                  Please note that if you don't provide your PAN number, you will not be able to claim 50% tax exemption u/s 80G in India.
                </p>
              </>
            )}
            {citizenship !== "indian" && (
              <div className="flex items-start space-x-2">
              <input
                type="checkbox"
                id="declaration"
                checked={declaration}
                onChange={(e) => setDeclaration(e.target.checked)}
                className="mt-1"
              />
              <label htmlFor="declaration" className="text-sm mb-4">
                I hereby declare that the information provided is true and accurate to the best of my knowledge.
              </label>
            </div>
            
            )}
            <button
              onClick={handlePayment}
              className={`w-full text-white py-2 rounded-lg ${
                (citizenship === "indian" || declaration) 
                  ? "bg-green-600 hover:bg-green-700" 
                  : "bg-gray-400 cursor-not-allowed"
              }`}
              disabled={citizenship !== "indian" && !declaration} 
            >
              Donate with Razorpay
            </button>


            {error && <p className="text-red-600 mt-4">{error}</p>}
          </div>
        </div>
              <div className="flex flex-col justify-center md:-mt-32">
                <h3 className="text-xl text-green-600">DONATION</h3>
                <h1 className="text-3xl md:text-4xl font-bold mb-6 text-gray-800">
                  Make a Difference, Your Support <span className="text-green-600">Changes Lives</span>
                </h1>
      
                <div className="grid grid-cols-1 gap-4 mt-6">
                  <div className="bg-white p-4 rounded-lg shadow-lg border-l-4 border-green-500">
                  <FontAwesomeIcon icon={faGraduationCap} size="2x" className="text-green-600" />
                    <h3 className="text-lg font-semibold mb-2">Education</h3>
                    <p className="text-gray-600">We believe education transforms lives and builds stronger communities.</p>
                  </div>
                  <div className="bg-white p-4 rounded-lg shadow-lg border-l-4 border-green-500">
                    <FontAwesomeIcon icon={faTree} size="2x" className="text-green-600"/>
                    <h3 className="text-lg font-semibold mb-2">Environment</h3>
                    <p className="text-gray-600">Protecting our environment is at the heart of everything we do.</p>
                  </div>
                  <div className="bg-white p-4 rounded-lg shadow-lg border-l-4 border-green-500">
                    <FontAwesomeIcon icon={faHeartbeat} size="2x" className="text-green-600"/>
                    <h3 className="text-lg font-semibold mb-2">Healthcare</h3>
                    <p className="text-gray-600">Accessible healthcare is essential for a thriving community.</p>
                  </div>
                </div>
              </div>
            </div>


      {showModal && <ThankYouModal />}
    </div>
  );
};

export default DonationPage;
 
import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

import image1 from "../assets/images/1.jpg";
import image2 from "../assets/images/image2.jpeg";
import image3 from "../assets/images/image3.jpg";
import ResultsSection from './HomeResultsSection';

const slides = [
  { id: 1, image: image1, title: 'Support Our Cause', description: 'Join us in making a difference.' },
  { id: 2, image: image2, title: 'Support Our Cause', description: 'Join us in making a difference.' },
  { id: 3, image: image3, title: 'Support Our Cause', description: 'Join us in making a difference.' },
];

const HeroSection = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const navigate = useNavigate(); 

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  const handleDonateNowClick = () => {
    navigate('/donate'); 
  };

  return (
    <>
      <div className="relative flex items-center justify-center h-[calc(70vh)] sm:h-[60vh] md:h-[65vh] lg:h-[70vh] xl:h-[75vh]">
        <AnimatePresence>
          {slides.map((slide, index) => (
            <motion.div
              key={slide.id}
              className="absolute inset-0 w-full h-full bg-cover bg-center transition-opacity duration-1000"
              style={{ backgroundImage: `url(${slide.image})` }}
              initial={{ opacity: 0 }}
              animate={{ opacity: currentSlide === index ? 1 : 0 }}
              exit={{ opacity: 0 }}
            >
              <div className="bg-black bg-opacity-50 h-full w-full flex flex-col justify-center items-center text-white p-4">
                <h2 className="text-3xl sm:text-5xl font-bold mb-2 text-center">{slide.title}</h2>
                <p className="text-base sm:text-xl mb-4 text-center">{slide.description}</p>
                <button 
                  onClick={handleDonateNowClick} 
                  className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-md transition-colors duration-300 focus:outline-none">
                  Donate Now
                </button>
              </div>
            </motion.div>
          ))}
        </AnimatePresence>

        <button
          onClick={() => setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length)}
          className="absolute left-0 ml-4 bg-black bg-opacity-50 text-white p-2 rounded-full focus:outline-none"
        >
          &lt;
        </button>
        <button
          onClick={() => setCurrentSlide((prev) => (prev + 1) % slides.length)}
          className="absolute right-0 mr-4 bg-black bg-opacity-50 text-white p-2 rounded-full focus:outline-none"
        >
          &gt;
        </button>

        <div className="absolute bottom-4 flex space-x-2">
          {slides.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentSlide(index)}
              className={`w-3 h-3 rounded-full ${index === currentSlide ? 'bg-white' : 'bg-gray-400'}`}
            />
          ))}
        </div>
      </div>

      <div className="text-center bg-gray-100 py-1">
        <p className="text-lg font-semibold text-green-600">
          Save tax on your donation. Get 50% tax exemption u/s 80G
        </p>
      </div>

      <ResultsSection />
    </>
  );
};

export default HeroSection;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGraduationCap, faHeartbeat, faHome, faTree, faHandsHelping, faGavel } from '@fortawesome/free-solid-svg-icons';
import PagesResultsSection from './PagesResultsSection';

const HelpSection = () => {
  const helpItems = [
    {
      icon: faGraduationCap,
      title: "Quality Education",
      description: "The NGO runs schools and vocational training centers, offering education to underprivileged children and youth, including those with special needs."
    },
    {
      icon: faHeartbeat,
      title: "Healthcare Access",
      description: "They provide healthcare through hospitals and clinics, focusing on the well-being of vulnerable groups."
    },
    {
      icon: faHome,
      title: "Social Support",
      description: "The NGO operates shelters and support centers for women, children, the elderly, and those facing addiction."
    },
    {
      icon: faTree,
      title: "Environmental Conservation",
      description: "They promote sustainability through tree planting, waste management, and natural resource protection."
    },
    {
      icon: faHandsHelping,
      title: "Aid for the Needy",
      description: "The NGO offers legal aid, financial support, and basic services to marginalized communities."
    },
    {
      icon: faGavel,
      title: "Cultural Preservation",
      description: "They work to preserve and promote traditional arts, music, and therapies."
    }
  ];

  return (
    <>
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 py-12 px-6 pt-10">
        <div className="max-w-7xl mx-auto">
          <h3 className="text-green-600 uppercase text-xl text-center mb-4">How we help?</h3>
          <h2 className="text-3xl md:text-4xl font-extrabold text-gray-800 leading-snug mb-4 text-center">
            What are we doing to assist these communities?
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {helpItems.map((item, index) => (
              <div
                key={index}
                className="group bg-white rounded-lg shadow-lg overflow-hidden p-6 text-center transform transition-transform duration-500 md:hover:scale-105 md:hover:shadow-xl border-b-4 border-green-600"
              >
                <div className="flex items-center justify-center w-24 h-24 mx-auto mb-4 rounded-full bg-green-100 text-green-700">
                  <FontAwesomeIcon icon={item.icon} size="2x" />
                </div>
                <h3 className="text-lg font-semibold text-gray-800 group-hover:text-green-600">
                  {item.title}
                </h3>
                <p className="text-gray-600 mt-2">{item.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <PagesResultsSection />
    </>
  );
};

export default HelpSection;

